import {
  GET_PRODUCTS_ANALTICS,
  GET_BEST_PERFORMING_PRODUCTS,
  GET_PRODUCTS_DASHBOARD,
  GET_PAYMENT_METHODS,
  DELETE_PAYMENT_METHODS,
  CHANGE_DEFAULT_PAYMENT_METHODS,
  GET_PRODUCT_DETAILS,
  GET_BOOSTED_PRODUCTS,
  GET_TRANSACTIONS,
  GET_CUSTOMERS,
  GET_ORDERS,
  GET_CARTS,
  GET_TAGS,
  GET_ALERTS,
  GET_DASHBOARD_CHART,
  GET_PRODUCTS_DASHBOARD_VERIFIED,
  GET_AVERAGE_CPC,
} from '../Constants'
import _ from 'lodash'
import moment from 'moment'

const initState = {
  analyticsLoading: true,
  analyticsLoaded: false,
  averageCPC: '0',
  analytics: {
    startDate: moment().subtract(4, 'months'),
    endDate: moment(),
    totalImpressions: 0,
    totalClicks: 0,
    totalProducts: 0,
    totalOpens: 0,
    ctr: 0,
    cpc: 0,
    totalSalesAmount: 0,
    totalProductsSold: 0,
    totalShares: 0,
    totalLikes: 0,
  },
  dashboardChart: [],
  bestPerformingProducts: [],
  bestProductsLoading: true,
  dashboardProducts: [],
  dashboardProductsVerified: [],
  dashboardProductsHasMore: false,
  dashboardProductsPage: 1,
  paymentMethods: [],
  productDetails: {},
  boostedProducts: [],
  transactions: [],
  orders: [],
  carts: [],
  customers: [],
  alerts: [],
  tags: [],
}

const appReducer = function (state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_PRODUCTS_ANALTICS:
      return {
        ...state,
        analyticsLoading: false,
        analyticsLoaded: true,
        analytics: {
          startDate: payload.startDate,
          endDate: payload.endDate,
          totalImpressions:
            payload.totalImpressions !== null ? payload.totalImpressions : 0,
          totalClicks: payload.totalClicks !== null ? payload.totalClicks : 0,
          totalProducts:
            payload.totalProducts !== null ? payload.totalProducts : 0,
          totalOpens: payload.totalOpens !== null ? payload.totalOpens : 0,
          ctr: payload.ctr !== null ? payload.ctr : 0,
          cpc: payload.cpc !== null ? payload.cpc : 0,
          totalSalesAmount: payload.totalSalesAmount,
          totalProductsSold: payload.totalProductsSold,
          totalShares: payload.totalShares,
          totalLikes: payload.totalLikes,
        },
      }
    case GET_DASHBOARD_CHART:
      return {
        ...state,
        dashboardChart: payload.chartData,
      }
    case GET_AVERAGE_CPC:
      return {
        ...state,
        averageCPC: payload,
      }
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      }
    case GET_TAGS:
      return {
        ...state,
        tags: payload,
      }
    case GET_ALERTS:
      return {
        ...state,
        alerts: payload,
      }
    case GET_PRODUCTS_DASHBOARD:
      return {
        ...state,
        dashboardProducts:
          payload.page == 1
            ? _.uniqBy(payload.Products, '_id')
            : _.uniqBy(
                [...state.dashboardProducts, ...payload.Products],
                '_id'
              ),
        dashboardProductsPage: payload.page,
        dashboardProductsHasMore: payload.hasMore,
      }
    case GET_PRODUCTS_DASHBOARD_VERIFIED:
      return {
        ...state,
        dashboardProductsVerified: payload.Products,
      }
    case GET_BEST_PERFORMING_PRODUCTS:
      return {
        ...state,
        bestProductsLoading: false,
        bestPerformingProducts: payload,
      }
    case GET_BOOSTED_PRODUCTS:
      return {
        ...state,
        boostedProducts: payload,
      }
    case GET_ORDERS:
      return {
        ...state,
        orders: payload,
      }
    case GET_CARTS:
      return {
        ...state,
        carts: payload,
      }
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: payload,
      }
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: payload,
      }
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: payload,
      }
    case CHANGE_DEFAULT_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: payload,
      }
    case DELETE_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          (item) => item.id !== payload
        ),
      }

    default:
      return { ...state }
  }
}

export default appReducer
