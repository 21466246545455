import axios from 'axios'

const apiRequest = (options) =>
  new Promise((resolve, reject) => {
    const isClient = typeof window !== 'undefined'

    // Define headers
    const headers = {
      ...options.headers,
    }

    // Only add custom headers if on the client side
    if (isClient) {
      headers['custom-country'] = localStorage.getItem('country')
      headers['custom-currency'] = localStorage.getItem('currency')
    }

    axios({
      // baseURL: "https://searchagora.herokuapp.com/",
      // baseURL: 'http://localhost:4000/',
      // Include this line to send cookies with the request
      withCredentials: true,
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      ...options,
      headers,
    })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error)
        if (error?.response?.data?.data) {
          // error from server
          console.log('Errors from Server: ', error.response.data)
        } else {
          // error in request
          console.error('Error Message:', error.message)
        }
        if (error?.response?.status === 401) {
          return reject('Unauthorized')
        }
        console.log(error)
        reject(error?.response?.data || error.message)
      })
  })

export default apiRequest
